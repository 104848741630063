<template lang="html">
  <div :class='{"tabs__light": mode === "light", "tabs__dark": mode === "dark"}'>
    <ul class='tabs__header'>
      <li v-for='(tab, index) in tabs'
        :key='tab.title'
        @click='selectTab(index)'
        :class='{"tab__selected": (index == selectedIndex)}'>
        {{ tab.title }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    mode: {
      type: String,
      default: "light"
    }
  },
  data() {
    return {
      selectedIndex: 0, // the index of the selected tab,
      tabs: [] // all of the tabs
    };
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.selectTab(0);
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i;

      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
    }
  }
};
</script>

<style lang="css">
ul.tabs__header {
  display: block;
  list-style: none;
  margin: 0 0 0 2px;
  padding: 0;
}

ul.tabs__header > li {
  padding: 8px 30px;
  border-radius: 3px;
  margin: 0;
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
}

ul.tabs__header > li.tab__selected {
  font-weight: bold;
  border: 1px solid #727272;
}

.tab {
  display: inline-block;
  color: black;
  border-radius: 10px;
  padding: 5px;
}

.tabs__light .tab {
  background-color: #fff;
}

.tabs__light li {
  background-color: rgb(241, 241, 241);
  color: rgb(0, 0, 0);
}

.tabs__light li.tab__selected {
  background-color: #ffff;
  color: #000;
}

.tabs__dark .tab {
  background-color: #555;
  color: #eee;
}

.tabs__dark li {
  background-color: rgb(238, 238, 238);
  color: #000;
}

.tabs__dark li.tab__selected {
  background-color: #555;
  color: white;
}
</style>