<template>
  <div class="w-full mb-5">
    <div class="w-full lg:flex">
      <div class="lg:w-60 flex-none text-left overflow-hidden" title="RoomName">
        <splide :slides="roomType.slides" :options="slideOptions">
          <splide-slide v-for="(slide, i) in roomType.slides" :key="i">
            <img
              v-if="slide"
              :src="`${$customConfig.api_host}/uploads/${slide}`"
              alt="slide.alt"
            />
          </splide-slide>
        </splide>
      </div>

      <div
        class="relative w-full border-r border-b border-l border-grey-light lg:border-l-0 lg:border-t lg:border-grey-light bg-white p-4 flex flex-col justify-between leading-normal"
      >
        <img
          @click="amenitiesShown = !amenitiesShown"
          src="@/assets/Arrow-down.svg"
          alt="View Amenities"
          title="View Amenities"
          class="w-5 h-5 absolute right-6 top-6 cursor-pointer z-50"
        />
        <div class="mb-8">
          <p class="text-sm text-grey-dark flex items-left">
            {{ property.name }}
          </p>
          <div class="text-black font-bold text-xl mb-2 text-left">
            {{ roomType.name }}
          </div>
          <p
            v-html="roomType.description"
            class="overflow-clip overflow-hidden text-grey-darker text-left text-base"
          >
            {{ roomType.description }}
          </p>
        </div>

        <div class="flex text-gray-700">
          <div class="flex-1 inline-flex items-left">
            <div class="text-sm">
              <p class="text-black text-left leading-none">Price for 1 Night</p>
              <p class="text-grey-dark">starts from USD {{ roomType.price }}</p>
            </div>
          </div>
          <div class="flex-1 z-20 items-end" v-if="!displayOnly">
            <p class="text-black text-right z-20">
              <button
                @click="bookRoom(roomType)"
                type="button"
                class="border border-gray-300 text-gray-700 z-20 rounded-md px-3 py-1 mr-2 transition duration-500 ease select-none hover:bg-gray-300 focus:outline-none focus:shadow-outline"
              >
                Book Now
              </button>
            </p>
          </div>
        </div>
        <div
          class="opacity-0 hover:opacity-100 duration-300 absolute z-10 inset-0 -right-0 pr-2 pt-2  text-right  text-xs text-gray-400"
        >
          View Amenities
        </div>
      </div>
    </div>
    <transition name="dropdown">
      <div v-show="amenitiesShown" class="bg-white p-5">
        <h3 class="text-2xl font-bold text-left mb-5">Amenities</h3>
        <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5">
          <div
            class="p-4 shadow flex items-center justify-left"
            v-for="amenity in roomType.amenities"
            :key="amenity._id"
          >
            <img
              class="w-6 h-6 mr-3"
              :src="`${$customConfig.api_host}/uploads/${amenity.image}`"
            />
            {{ amenity.name }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "RoomCard",
  data() {
    return {
      amenitiesShown: false || this.$props.showAmenities,
      slideOptions: {
        type: "loop",
        perPage: 1,
        perMove: 1,
        fixedWidth: 380,
        fixedHeight: 220,
        focus: "center",
        cover: true,
        gap: "0rem",
        pagination: false
      }
    };
  },
  props: ["property", "roomType", "displayOnly", "showAmenities"],
  methods: {
    bookRoom(room) {
      this.$store.commit("SET_BOOKING_FIELD", {
        field: "property",
        value: this.property
      });
      this.$store.commit("SET_BOOKING_FIELD", {
        field: "roomType",
        value: room
      });
      this.$router.push("/booking");
    }
  }
};
</script>

<style scoped></style>
