<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <SearchBar></SearchBar>

    <div
      v-if="specialOffer.name"
      class="p-6 bg-green-100 border border-green-600 font-bold text-2xl uppercase"
    >
      <img
        src="/img/greentick.png"
        width="100px"
        style="float: left;"
        class="-m-4 mr-2 opacity-75"
      />
      SPECIAL OFFER {{ $store.state.booking.specialOffer.name }} FOR
      {{ $store.state.booking.property.name }}
      <span class="block text-sm mt-4"
        >Please select a room type to continue to the booking form.</span
      >
    </div>

    <div class="flex md:flex-row mx-2 flex-wrap">
      <div class="w-full md:w-1/2 bg-white p-4 text-center text-gray-200">
        <h2
          class="text-2xl text-left text-black tracking-wide font-semibold leading-loose mb-2"
        >
          {{ property.name }}
          <span class="text-sm">
            <i
              v-for="n in property.starRating"
              :key="n"
              class="text-yellow-400 fas fa-star"
            ></i>
            <i class="text-black pl-1 fas fa-map-marker-alt"></i>
            {{ property.location }}
          </span>
        </h2>

        <!--     
        <splide :options="primaryOptions" ref="primary">
          <splide-slide v-for="slide in property.slides" :key="slide">
            <img :src="`${$customConfig.api_host}/uploads/${slide}`" alt="" />
          </splide-slide>
        </splide>

        <splide :options="secondaryOptions" ref="secondary">
          <splide-slide v-for="slide in property.slides" :key="slide">
            <img
              :src="`${$customConfig.api_host}/uploads/${slide}`"
              alt="slide.alt" 
            />
          </splide-slide>
        </splide> -->

        <splide
          :slides="property.slides"
          :options="primaryOptions"
          ref="primary"
        >
          <splide-slide
            style="width: 100%"
            v-for="(slide, i) in property.slides"
            :key="i"
          >
            <img
              :src="`${$customConfig.api_host}/uploads/${slide}`"
              alt="slide.alt"
            />
          </splide-slide>
        </splide>

        <splide
          style="margin-top: 0.1rem"
          :slides="property.slides"
          :options="secondaryOptions"
          ref="secondary"
        >
          <splide-slide v-for="(slide, i) in property.slides" :key="i">
            <img
              :src="`${$customConfig.api_host}/uploads/${slide}`"
              alt="slide.alt"
            />
          </splide-slide>
        </splide>

        <div
          class="flex md:flex-row inline-block text-sm items-center justify-left py-5 text-black"
        ></div>

        <div class="relative">
          <div class="text-left mt-2 text-black">
            <tabs :mode="mode" class="">
              <tab title="Description">
                <span class="p-4" v-html="property.description">
                  {{ property.description }}
                </span>
              </tab>
              <tab title="Policy">
                <div class="p-4">
                  <br />

                  <read-more
                    more-str=" Read More "
                    :text="property.policy"
                    link="#"
                    less-str="Read Less"
                    :max-chars="600"
                  ></read-more>
                </div>
              </tab>
            </tabs>
          </div>

          <div class="bg-white p-5">
            <h3 class="text-2xl font-bold text-black text-left mb-5">
              Facilities
            </h3>
            <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5">
              <div
                class="p-4 shadow flex items-center text-black justify-left"
                v-for="facility in property.facilities"
                :key="facility._id"
              >
                <img
                  class="w-6 h-6 mr-3"
                  :src="`${$customConfig.api_host}/uploads/${facility.image}`"
                />
                {{ facility.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 bg-gray-100 p-4 text-center text-gray-700">
        <h2
          class="text-2xl text-right text-black tracking-wide font-semibold leading-loose mb-2"
        >
          Book a room
        </h2>
        <!-- PLACEHOLDER -->
        <room-card
          v-for="roomType in property.roomTypes"
          :key="roomType._id"
          :property="property"
          :roomType="roomType"
        ></room-card>
        <div class="trans-all">
          <h2
            class="text-2xl text-right text-black tracking-wide font-semibold leading-loose mb-2"
          >
            Special Offers
          </h2>

          <div
            v-for="offer in property.specialOffers"
            :key="offer._id"
            class="w-full lg:flex mb-5"
          >
            <div
              class="w-full p-8 border-r border-b border-l border-blue-light lg:border-l-1 lg:border-t lg:border-blue-light bg-white p-4 flex flex-col justify-between leading-normal"
            >
              <div class="mb-0">
                <p class="text-sm text-grey-dark flex items-left">
                  {{ property.name }}
                </p>

                <div
                  class="flex md:flex-row inline-block items-center justify-left border-gray-300 text-gray-700"
                >
                  <div class="text-black font-bold text-lg text-left">
                    {{ offer.name }}
                  </div>
                  <div class="flex-1 inline-flex text-black justify-end">
                    <button
                      type="button"
                      class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 mr-6 transition duration-500 ease select-none hover:bg-gray-300 focus:outline-none focus:shadow-outline"
                    >
                      Details
                    </button>
                    <button
                      @click="
                        $store.commit('ADD_SPECIAL_OFFER', offer);
                        $store.commit('SET_BOOKING_FIELD', {
                          field: 'property',
                          value: property
                        });
                        $window.scrollTo({ top: 0, behavior: 'smooth' });
                      "
                      type="button"
                      class="border border-gray-200 bg-gray-200 text-gray-700 rounded-md px-4 py-2 transition duration-500 ease select-none hover:bg-gray-300 focus:outline-none focus:shadow-outline"
                    >
                      Select
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="max-w-6xl mx-auto mt-6">
      <div class="flex flex-wrap">
        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img src="/img/3309285-200.png" width="75px" />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Luxury Maldives Experience
              </h2>
              <p class="text-sm">
                We can guarentee peace of mind when booking with us
              </p>
            </div>
          </div>
        </div>

        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img src="/img/3533484-200.png" width="75px" />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Travel Insurance Protection
              </h2>
              <p class="text-sm">
                Our holiday packages are financially protected by Travel
                Insurance
              </p>
            </div>
          </div>
        </div>

        <div class="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-white">
          <div class="w-full py-6 px-6 text-gray-800 place-content-center">
            <div class="text-center pb-4 mb-4 w-full">
              <center>
                <img src="/img/3188775-200.png" width="75px" />
              </center>
              <h2 class="font-bold text-m uppercase text-gray-800">
                Flexible Payments
              </h2>
              <p class="text-sm">
                Spread the cost of your luxury holiday with monthly payments
                with easy online payments
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="clear: both; display: block; height: 0px"></div>

    <FooterLayout></FooterLayout>
  </div>
</template>

<script>
import axios from "axios";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";
import RoomCard from "@/components/others/RoomCard";
import SearchBar from "@/components/others/SearchBar";
import Tab from "@/components/others/Tab";
import Tabs from "@/components/others/Tabs";

import { createSlides } from "../others/slides.js";

export default {
  name: "ResortPage",
  data() {
    return {
      property: {
        slides: [],
        policy: ""
      },
      specialOffers: [],
      roomTypes: [],
      totalPages: "",

      currentPage: this.$route.query.page * 1 || 1,
      primaryOptions: {
        type: "loop",
        perPage: 1,
        perMove: 1,
        autoWidth: false,
        fixedHeight: 520,
        gap: "1rem",
        cover: true,
        pagination: false
      },
      secondaryOptions: {
        type: "loop",
        rewind: true,
        gap: "0.1rem",
        pagination: false,
        cover: true,
        focus: "center",
        fixedWidth: 120,
        fixedHeight: 80,
        isNavigation: false,
        updateOnMove: true
      },
      slides: createSlides(),
      count: 0
    };
  },
  // eslint-disable-next-line
  beforeRouteLeave(to, from, next) {
    if (/booking/gi.test(to.path)) {
      next();
    } else {
      this.$store.commit("SET_BOOKING_FIELD", {
        field: "specialOffer",
        value: { name: "", percentage: "" }
      });
      next();
    }
  },
  mounted() {
    this.getProperties();
    // this.getSpecialOffers();

    // Set the sync target.
    this.$refs.primary.sync(this.$refs.secondary.splide);
  },
  methods: {
    async getProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties/${this.$route.params.slug}?name=true`
        );

        this.property = response.data.property;
        this.totalPages = response.data.totalPages;
        console.log("e");
      } catch (e) {
        console.log(e);
      }
    }
    // async getSpecialOffers() {
    //   try {
    //     const response = await axios.get(
    //       `${this.$customConfig.api_host}/api/v1/properties/${this.$route.params.propertyid}/specialOffers`
    //     );
    //     if (response.status === 200) {
    //       this.specialOffers = response.data.specialOffers;
    //       this.currentPropertyName = response.data.currentPropertyName;
    //     }
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
  },
  computed: {
    specialOffer() {
      return this.$store.state.booking.specialOffer;
    }
  },
  components: {
    HeaderLayout,
    FooterLayout,
    RoomCard,
    SearchBar,
    Tab,
    Tabs
  }
};
</script>

<style scoped>
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
           WDM
 –––––––––––––––––––––––––––––––––––––––––––––––––– */

.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}
.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}
#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}
.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}
#carousel-1:checked
  ~ .control-1
  ~ .carousel-indicators
  li:nth-child(1)
  .carousel-bullet,
#carousel-2:checked
  ~ .control-2
  ~ .carousel-indicators
  li:nth-child(2)
  .carousel-bullet,
#carousel-3:checked
  ~ .control-3
  ~ .carousel-indicators
  li:nth-child(3)
  .carousel-bullet {
  color: #2b6cb0; /*Set to match the Tailwind colour you want the active one to be */
}

/*each image is referenced twice in the HTML, but each image only needs to be updated in the CSS*/
.image1 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070e73d01476.jpg");
}

.image2 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070e35541455.jpg");
}

.image3 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070e5763146a.jpg");
}

.image4 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070eaec71491.jpg");
}

.image5 {
  content: url("https://images.capitaltravel.com/4028e49970f7fb9d0171070ebff014a0.jpg");
}

.transall {
  transition: all 0.5s;
}
</style>


